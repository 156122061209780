<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->

          <h2 class="brand-text text-primary ml-1">print qrcode</h2>
        </b-link>

        <b-card-title class="mb-1"> สร้างเอกสารสำหรับสั่งพิมพ์ </b-card-title>
        <b-card-text class="mb-2">
          ไฟล์ที่ถูกสร้างจะเป็นไฟล์นามสกุล .PDF และจะทำการปริ้นเฉพาะ QR Code
          ที่มีสถานะเป็น "not Printed" เท่านั้น
        </b-card-text>

        <!-- form -->

        <b-form class="auth-forgot-password-form mt-2">
          <!-- email -->
          <!-- <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

          <!-- submit button -->
          <b-button
            variant="primary"
            block
         
            @click="PrintQrCode()"
          >
            generate PDF
          </b-button>
        </b-form>

        <!-- <b-card-text class="text-center mt-2">
          <b-link :to="{name:'qrcodes'}">
             download now
          </b-link>
        </b-card-text> -->
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from "axios";
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      url,
      response,
      blob,
      downloadUrl: null,
    downloadName: 'cards.pdf'

    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'auth-reset-password-v1' })
        }
      })
    },


    async PrintQrCode() {
      try {
        const response =  await axios.get(`http://localhost:3000/api/qrcodes/download`,{
      responseType: 'blob', // บอก Axios ว่าคาดว่า response จะเป็น Blob
			headers: {
				'Content-Type': 'application/pdf',
				'Content-Disposition': 'attachment; filename=cards.pdf'
			}

      
		});

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'cards.pdf';
    link.click();

      } catch (error) {
        console.error("Error deleting QR code:", error);
      }
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
